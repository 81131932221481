<script setup lang="ts">
import useModalsStore from '~/stores/modals'

const modalsStore = useModalsStore()
</script>

<template>
  <div class="advantages space">
    <div class="container">
      <div class="advantages__content">
        <div class="advantages__pre-title" data-aos="fade-right">{{ $t('advantages.pretitle') }}</div>
        <h2 class="advantages__title" data-aos="fade-right" data-aos-delay="100">{{ $t('advantages.title') }}</h2>

        <div class="advantages__items">
          <div class="advantages__item" data-aos="fade-up">
            <div class="advantages__item-icon">
              <img src="/img/gradient-icons/repair-icon.svg" alt="icon" />
            </div>
            <div class="advantages__item-title">{{ $t('advantages.item1.title') }}</div>
            <div class="advantages__item-description">{{ $t('advantages.item1.text') }}</div>
          </div>
          <div class="advantages__item" data-aos="fade-up" data-aos-delay="200">
            <div class="advantages__item-icon">
              <img src="/img/gradient-icons/design-icon.svg" alt="icon" />
            </div>
            <div class="advantages__item-title">{{ $t('advantages.item2.title') }}</div>
            <div class="advantages__item-description">{{ $t('advantages.item2.text') }}</div>
          </div>
          <div class="advantages__item" data-aos="fade-up" data-aos-delay="400">
            <div class="advantages__item-icon">
              <img src="/img/gradient-icons/solutions-icon.svg" alt="icon" />
            </div>
            <div class="advantages__item-title">{{ $t('advantages.item3.title') }}</div>
            <div class="advantages__item-description">{{ $t('advantages.item3.text') }}</div>
          </div>
          <div class="advantages__item" data-aos="fade-up" data-aos-delay="600">
            <div class="advantages__item-icon">
              <img src="/img/gradient-icons/bank-icon.svg" alt="icon" />
            </div>
            <div class="advantages__item-title">{{ $t('advantages.item4.title') }}</div>
            <div class="advantages__item-description">{{ $t('advantages.item4.text') }}</div>
          </div>
        </div>

        <div class="advantages__actions">
          <button
            class="btn btn--primary btn--large"
            data-aos="zoom-in"
            data-aos-delay="300"
            @click.prevent="modalsStore.open('contact')"
          >
            {{ $t('advantages.buttonText') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use '@/assets/scss/partials/advantages';
</style>