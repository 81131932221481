<template>
  <div v-memo="[$i18n.locale]" class="function-steps space">
    <div class="container">
      <div class="function-steps__content">
        <div class="function-steps__pre-title">{{ $t('functions.pretitle') }}</div>
        <h2 class="function-steps__title">{{ $t('functions.title') }}</h2>

        <div data-aos="fade-right" data-aos-duration="1000">
          <ol class="function-steps__items">
            <li
              data-aos="fade-right"
              data-aos-duration="1000"
              class="function-steps__item"
            >
              {{ $t('functions.step1') }}
            </li>
            <li
              data-aos="fade-right"
              data-aos-delay="300"
              data-aos-duration="1000"
              class="function-steps__item"
            >
              {{ $t('functions.step2') }}
            </li>
            <li
              data-aos="fade-right"
              data-aos-delay="600"
              data-aos-duration="1000"
              class="function-steps__item"
            >
              {{ $t('functions.step3') }}
            </li>
            <li
              data-aos="fade-right"
              data-aos-delay="900"
              data-aos-duration="1000"
              class="function-steps__item"
            >
              {{ $t('functions.step4') }}
            </li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use '@/assets/scss/partials/function-steps';
</style>