<script setup lang="ts">
import { EffectCards, EffectFade, Controller, Autoplay } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/vue'
import ParallaxDivider from '~/components/ParallaxDivider.vue'

const i18n = useI18n()

useSeoMeta({
  title: i18n.t('seo.index.title'),
  ogTitle: i18n.t('seo.index.title'),
  description: i18n.t('seo.index.description'),
  ogDescription: i18n.t('seo.index.description'),
  ogImage: '/img/logo.png',
  ogType: 'website',
  ogUrl: '/',
  twitterCard: 'summary_large_image',
})

const config = useRuntimeConfig()

const { data, pending } = await useFetch<any>(`${config.public.apiUrl}/complexes`)

const complexes: any = computed(() => data.value?.body || [])
const backgroundSwiper = ref<any>()

const setControlledSwiper = (swiper: any) => {
  backgroundSwiper.value = swiper
}

const isDesktop = useMediaQuery('(min-width: 992px)')

const currentItemIndex = ref(0)
const currentItemLink = computed(() => complexes.value[currentItemIndex.value]?.redirect_url)
const currentItemComplexName = computed(() => complexes.value[currentItemIndex.value]?.complex_name)

function setCurrentItem(index: number) {
  currentItemIndex.value = index
}
</script>

<template>
  <div class="page">
    <Body class="homepage" />
    <div v-if="!pending" class="welcome">
      <div class="welcome__background-slider">
        <ClientOnly>
          <Swiper
            class="welcome__background-swiper"
            effect="fade"
            :modules="[EffectFade, Controller]"
            @swiper="setControlledSwiper"
            @slide-change="setCurrentItem($event.realIndex)"
          >
            <SwiperSlide
              v-for="(item, index) in complexes"
              :key="index"
            >
              <img :src="(!item.cover_photo || item.cover_photo === 'https://crm-estate.midavco.com/storage') ? '/img/complex-demo-placeholder.jpg' : item.cover_photo" alt />
            </SwiperSlide>
          </Swiper>
        </ClientOnly>
      </div>
      <div class="container">
        <div class="welcome__content">
          <div class="welcome__info">
            <div class="welcome__pre-title">{{ $t('welcome.pretitle') }}</div>
            <div class="welcome__title h1" v-html="$t('welcome.title', { complex: currentItemComplexName })"></div>
            <div class="welcome__actions">
              <NuxtLinkLocale
                :to="currentItemLink"
                class="btn btn--primary btn--large"
              >
                {{ $t('welcome.buttonText') }}
              </NuxtLinkLocale>
              <a
                href="https://www.instagram.com/estate_invest_company?igsh=Y3FyMDY4bmV4NDBk"
                class="btn btn--tertiary btn--large"
                target="_blank"
              >
                {{ $t('welcome.instagramText') }}
              </a>
            </div>
          </div>
          <div class="welcome__nav-slider">
            <ClientOnly>
              <Swiper
                v-if="isDesktop"
                class="welcome__nav-swiper welcome__nav-swiper--desktop"
                direction="vertical"
                effect="cards"
                :rewind="true"
                :cards-effect="{
                  perSlideRotate: 0,
                  perSlideOffset: 16,
                }"
                :grab-cursor="true"
                :autoplay="{
                  delay: 6000,
                  disableOnInteraction: false,
                  pauseOnMouseEnter: true
                }"
                :modules="[EffectCards, Controller, Autoplay]"
                :controller="{ control: backgroundSwiper }"
              >
                <SwiperSlide
                  v-for="(item, index) in complexes"
                  :key="index"
                >
                  <div class="welcome__nav-slider-item">
                    <div class="welcome__nav-slider-text">
                      <div v-if="item.complex_name" class="welcome__nav-slider-title">{{ item.complex_name }}</div>
                      <div v-if="item.address" class="welcome__nav-slider-description">{{ item.address }}</div>
                    </div>
                    <img :src="(!item.cover_photo || item.cover_photo === 'https://crm-estate.midavco.com/storage') ? '/img/complex-demo-placeholder.jpg' : item.cover_photo" alt />
                  </div>
                </SwiperSlide>
              </Swiper>

              <Swiper
                v-else
                class="welcome__nav-swiper welcome__nav-swiper--mobile"
                slides-per-view="auto"
                space-between="12"
                :rewind="true"
                :grab-cursor="true"
                :autoplay="{
                  delay: 6000,
                  disableOnInteraction: true
                }"
                :modules="[Autoplay]"
                @slide-change="backgroundSwiper?.slideTo($event.realIndex);"
              >
                <SwiperSlide
                  v-for="(item, index) in complexes"
                  :key="index"
                  @click="backgroundSwiper?.slideTo(index)"
                >
                  <div
                    class="welcome__nav-slider-item"
                    :class="{ 'active': index === backgroundSwiper?.realIndex }"
                  >
                    <div class="welcome__nav-slider-text">
                      <div v-if="item.complex_name" class="welcome__nav-slider-title">{{ item.complex_name }}</div>
                      <div v-if="item.address" class="welcome__nav-slider-description">{{ item.address }}</div>
                    </div>
                    <img :src="(!item.cover_photo || item.cover_photo === 'https://crm-estate.midavco.com/storage') ? '/img/complex-demo-placeholder.jpg' : item.cover_photo" alt />
                  </div>
                </SwiperSlide>
              </Swiper>
            </ClientOnly>
          </div>
        </div>
      </div>
    </div>

    <ExperienceSection />

    <ParallaxDivider />

    <ComplexesGrid :limit="4" />

    <ParallaxDivider />

    <FunctionSteps />

    <ParallaxDivider />

    <AdvantagesSection />
  </div>
</template>

<style scoped lang="scss">
@use '@/assets/scss/partials/welcome';
</style>