<script setup lang="ts">
import CountUp from 'vue-countup-v3'

import useModalsStore from '~/stores/modals'

const modalsStore = useModalsStore()

const i18n = useI18n()

const items = computed(() => [
  {
    value: 9,
    valueUnit: i18n.t('experience.years'),
    text: i18n.t('experience.experience')
  },
  {
    value: 200000,
    valueUnit: 'm<sup>2</sup>',
    text: i18n.t('experience.built')
  },
  {
    value: 48,
    valueUnit: i18n.t('experience.buildings'),
    text: i18n.t('experience.developed')
  },
  {
    value: 27390,
    valueUnit: i18n.t('experience.clients'),
    text: i18n.t('experience.remainedSatisfied')
  }
])
</script>

<template>
  <div class="experience">
    <div class="container">
      <div class="experience__content space">
        <div class="experience__pre-title">{{ $t('experience.pretitle') }}</div>
        <h2 class="experience__title h2">{{ $t('experience.title') }}</h2>
        <div class="experience__items">
          <div
            v-for="(item) in items"
            :key="item.text"
            class="experience__item"
          >
            <div class="experience__item-value">
              <span class="countup-wrapper">
                <CountUp
                  :start-val="1"
                  :end-val="item.value"
                  :duration="5"
                  :options="{
                    useEasing: false,
                    useGrouping: true,
                    separator: ' ',
                    enableScrollSpy: true,
                    scrollSpyOnce: true,
                  }"
                />
                <span class="countup-wrapper__final-value">{{ item.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") }}</span>
              </span>
              <span v-html="item.valueUnit"></span>
            </div>
            <div class="experience__item-text">{{ item.text }}</div>
          </div>
        </div>
        <button
          class="btn btn--primary btn--large"
          @click.prevent="modalsStore.open('contact')"
        >
          {{ $t('requestConsultation') }}
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/assets/scss/partials/experience';
</style>